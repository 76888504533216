import React, { useState, Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import useEffectLocation from "../hooks/useEffectLocation";

import "./style.scss";

const Home = React.lazy(() => import("../views/home"));
const NotFoundPage = React.lazy(() => import("../views/404"));
const BlogDetails = React.lazy(() => import("../views/blog/BlogDetails"));

const Shop = React.lazy(() => import("../views/Shop"));
const ProjectDetails = React.lazy(() =>
  import("../views/portfolio/ProjectDetails")
);

const About = React.lazy(() => import("../views/About"));
const Services = React.lazy(() => import("../views/Services"));

const Router = () => {
  const [transPage, setTransPage] = useState("in");
  const scrollbar = useSelector((state) => state.scrollbar);

  const location = useEffectLocation((l) => {
    setTransPage("out");
  });

  const onAnimateEnd = () => {
    if (transPage !== "out") return;
    scrollbar.current?.scrollTo(0, 0, 0);
    window.scrollTo({ left: 0, top: 0 });
    setTransPage("in");
  };

  return (
    <div
      id="dsn-content"
      className={`dsn-transition-page dsn-animate-${transPage}`}
      onAnimationEnd={onAnimateEnd}
    >
      <Suspense fallback={<div className="background-main h-100-v" />}>
        <Routes location={location}>
          <Route path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/curations" element={<Shop />} />
          <Route
            exact
            path="/curations/:productId"
            element={<ProjectDetails />}
          />
          <Route
            exact
            path="/blog-details/:blogId"
            element={<BlogDetails />}
          />
          <Route exact path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default React.memo(Router);
